<template>
  <div>
    <div class="row mt-5">
      <div class="col-md-10 offset-md-1">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h1 class="card-label">
                Shipment # {{ shipment.tracking_no }} -
                <span class="text-success">{{ currentStatus }}</span>
              </h1>
            </div>
            <!-- :disabled="shipment.payment_status != 1" -->
            <div class="card-toolbar float-right">
              <b-button v-if="currentUser.role != 6" variant="light" v-b-modal.add-shipment-status-modal><i
                  class="flaticon2-plus"></i> Update Status</b-button>
              <b-dropdown text="More options" class="m-2">
                <b-dropdown-item href="#" @click="printInvoice()">Print Invoice</b-dropdown-item>
                <b-dropdown-item href="#" @click="printPackageReceipt()">Package Receipt</b-dropdown-item>
                <b-dropdown-item href="#" @click="printShipmentLabel()">Shipment Label</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="card-body body-fluid">
            <v-container style="max-width: 600px !important">
              <v-timeline dense clipped>
                <v-slide-x-transition group>
                  <span v-for="(event, index) in shipment.shipmentstatuses" :key="index">
                    <v-timeline-item v-if="index == 0" class="mb-4" small>
                      <v-row justify="space-between">
                        <v-col cols="7">
                          <span class="text-muted"> {{ event.location }} </span><br />
                          <strong>{{ event.status }}</strong><br />
                          {{ event.remarks }}<br />
                          {{
                            new Date(event.datetime).toLocaleString([], {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          }}
                        </v-col>
                        <v-col class="text-right" cols="5">
                          <a class="mr-2" @click="EditShipmentStatus(event.id)" v-if="currentUser.role != 6">
                            <i class="flaticon2-edit icon-sm"></i> Edit
                          </a>
                          <a class="n" @click="deleteStatus(event.id)" v-if="currentUser.role != 6">
                            <i class="flaticon2-cross icon-sm"></i> Delete
                          </a>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                    <v-timeline-item v-if="index > 0" class="mb-4" color="grey" small>
                      <v-row justify="space-between">
                        <v-col cols="7">
                          <span class="text-muted"> {{ event.location }} </span><br />
                          <strong>{{ event.status }}</strong><br />
                          {{ event.remarks }}<br />
                          {{
                            new Date(event.datetime).toLocaleString([], {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          }}
                        </v-col>
                        <v-col class="text-right" cols="5">
                          <a class="mr-2" @click="EditShipmentStatus(event.id)" v-if="currentUser.role != 6">
                            <i class="flaticon2-edit icon-sm"></i> Edit
                          </a>
                          <a class="n" @click="deleteStatus(event.id)" v-if="currentUser.role != 6">
                            <i class="flaticon2-cross icon-sm"></i> Delete
                          </a>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </span>
                </v-slide-x-transition>
              </v-timeline>
            </v-container>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-md-2">
                <b>Tracking Link</b>
              </div>
              <div class="col-md-8">
                <b-form-input size="sm" id="copy-target" readonly v-model="trackingLink"></b-form-input>
              </div>
              <div class="col-md-2">
                <b-button class="btn-copy" id="btn-copy" @click="showCopyTooltip" data-clipboard-target="#copy-target"
                  size="sm" variant="light"><i class="flaticon2-copy"></i> Copy</b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Shipment Details</h3>
            </div>
            <b-button class="btn btn-light edit-shipment-btn" @click="ShowEditModal(shipment.id)"><i
                class="flaticon2-edit"></i> Edit Shipment</b-button>
          </div>
          <div class="card-body body-fluid">
            <div class="row">
              <div v-if="shipment.type == 1" class="col-md-12">
                <b-card border-variant="secondary" align="left">
                  <b-card-text>
                    <h5 class="header_style_2">Incoming Address</h5>
                    <b>{{ shipment.virtual_address.name }}</b>
                    {{ shipment.virtual_address.address_line_1 }},
                    {{ shipment.virtual_address.city_state }},
                    {{ shipment.virtual_address.country }} -
                    {{ shipment.virtual_address.zip }}
                  </b-card-text>
                </b-card>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-12">
                <b-card style="min-height: 190px" border-variant="secondary" align="left">
                  <b-card-text>
                    <span v-if="shipment.type == 1 && shipment.consolidation_required == 'No'">
                      <h5 class="header_style_2">Vendor Details</h5>
                      <div class="row">
                        <div class="col-md-6 margin-bottom-8">
                          <strong for="lastName" class="control-strong">Vendor Name</strong>
                          <br />{{ shipment.vendor_name }}
                        </div>
                        <div class="col-md-6 margin-bottom-8">
                          <strong for="lastName" class="control-strong">Courier Company</strong>
                          <br />{{ shipment.courier }}<br />
                        </div>
                        <div class="col-md-6" style="word-break: break-word">
                          <strong for="lastName" class="control-strong">Courier Tracking Number</strong>
                          <br />{{ shipment.courier_tracking_no }}
                        </div>
                        <div class="col-md-6">
                          <strong for="lastName" class="control-strong">Purchase Date
                          </strong>
                          <br />{{
                            new Date(
                              shipment.purchase_date
                            ).toLocaleDateString()
                          }}
                        </div>
                      </div>
                    </span>
                    <span v-if="shipment.type == 1 && shipment.consolidation_required == 'Yes'">
                      <h5 class="header_style_2">Vendor Details</h5>
                      <div class="row">
                        <div class="table-responsive">
                          <table class="table mt-2 table-hover">
                            <thead>
                              <tr>
                                <th> Name </th>
                                <th> Courier Company </th>
                                <th> Courier Tracking No.</th>
                                <th> Purchase Date </th>
                                <th> Purchase Proof </th>
                                <th> Action </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="item" v-for="(v, index) in shipment.vendors" :key="index">
                                <td>
                                  {{ v.vendor_name }}
                                </td>
                                <td>
                                  {{ v.courier }}
                                </td>
                                <td>
                                  {{ v.courier_tracking_no }}
                                </td>
                                <td>
                                  {{ new Date(
                                    v.purchase_date
                                  ).toLocaleDateString() }}
                                </td>
                                <td>
                                  <button type="button" @click="downloadVendorDocument(v.id)"
                                    class="btn btn-secondary mr-2">
                                    <i class="flaticon-attachment text-primary"></i>
                                    {{ v.file_name }}
                                  </button>
                                </td>
                                <td>
                                  <button class="btn btn-sm btn-warning" type="button" tooltip="Edit Vendor"
                                    @click="editVendorPopup(v)"> <i class="flaticon2-edit"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </span>
                    <span v-if="shipment.type == 2">
                      <h5 class="header_style_2">Sender Details</h5>
                      <b>{{ shipment.sender_name }}</b>
                      <br />{{ shipment.sender_phone }}
                      <br />
                      {{ shipment.sender_street_address }},<br />
                      {{ shipment.sender_city }},{{ shipment.sender_state }} ,
                      {{ shipment.sender_country }} -
                      {{ shipment.sender_zipcode }}
                    </span>
                  </b-card-text>
                </b-card>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-card style="min-height: 190px" border-variant="secondary" align="left">
                  <b-card-text>
                    <h5 class="header_style_2">Receiver Details</h5>
                    <b>{{ shipment.receiver_name }}</b>
                    <br />
                    {{ shipment.receiver_email }} <br />
                    {{ shipment.receiver_phone }} <br />
                    {{ shipment.receiver_street_address }}, <br />
                    {{ shipment.receiver_city }},{{ shipment.receiver_state }} ,
                    {{ shipment.receiver_country }} -
                    {{ shipment.receiver_zipcode }}
                  </b-card-text>
                </b-card>
              </div>
              <div v-if="shipment.type == 1" class="col-md-3 mt-3">
                <b>Reference No</b><br />
                {{ shipment.courier_tracking_no }}
              </div>
              <div class="col-md-3 mt-3">
                <b>Shipment Type</b><br />
                {{ shipment.shipment_type }}
              </div>
              <div class="col-md-3 mt-3">
                <b>Shipping Mode</b><br />
                {{ shipment.shipping_mode }}
              </div>
              <div class="col-md-3 mt-3">
                <b>Shipment Weight</b><br />
                {{ shipment.total_weight
                }}<span v-if="shipment.total_weight == null || shipment.total_weight == ''
  ">0</span>
                Kgs
              </div>
              <div class="col-md-3 mt-3">
                <b>Booking Date</b><br />
                {{ new Date(shipment.created_at).toLocaleDateString() }}
              </div>
              <div class="col-md-3 mt-3">
                <b>Expected Delivery</b><br />
                To be confirmed
              </div>
              <div class="col-md-3 mt-3">
                <b>Payer</b><br />
                {{ shipment.shipment_payer }}
              </div>
              <div class="col-md-3 mt-3">
                <b>Consolidation Required?</b><br />
                {{ shipment.consolidation_required }}
              </div>
              <div class="col-md-3 mt-3">
                <b>No. of pieces</b><br />
                {{ shipment.no_of_pieces }}
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 v-if="shipment.products != null" class="card-label">
                Package Details ({{ shipment.products.length }})
              </h3>
            </div>
            <div class="card-toolbar" v-if="currentUser.role != 6">
              <b-button variant="light" v-b-modal.add-product-modal><i class="flaticon2-plus"></i> Add Product</b-button>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive" id="productTable">
              <table class="table table-striped table-hover text-nowrap">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Dimensions</th>
                    <th>Dimension Wt.</th>
                    <th>Actual Wt.</th>
                    <th>Final Weight</th>
                    <th>Quantity</th>
                    <th>Value</th>
                    <th>Total</th>
                    <th>Manage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in shipment.products" :key="index">
                    <td>{{ item.description }}</td>
                    <td>
                      {{ item.length }} x {{ item.width }} x
                      {{ item.height }} ({{ item.size_scale }})
                    </td>
                    <td>{{ item.dimensional_weight }}</td>
                    <td>{{ item.actual_weight }}</td>
                    <td>{{ item.final_weight }} {{ item.weight_scale }}</td>
                    <td>{{ item.qty }}</td>
                    <td>{{ item.value }} {{ item.currency }}</td>
                    <td>{{ item.total }} {{ item.currency }}</td>
                    <td>
                      <b-button size="sm" @click="ShowProductEditModal(item.id)" variant="light">Edit</b-button>
                      <b-button size="sm" variant="light" @click="DeleteProduct(item.id)"
                        class="ml-2 text-danger">Delete</b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Shipping Charges</h3>
            </div>
            <div class="card-toolbar" v-if="currentUser.role != 6">
              <b-button variant="light" class="mr-2" v-b-modal.add-charges-modal><i class="flaticon2-plus"></i> Add
                Charges (Manual)</b-button>
              <b-button variant="light" v-if="currentUser.role != 6" @click="showAutoChargeModal"><i
                  class="flaticon2-refresh"></i> Add Charges (Auto)</b-button>
            </div>
          </div>
          <div class="card-body body-fluid">
            <div class="row">
              <div class="col-md-6 offset-md-4">
                <div class="table-responsive custom-scrollbar">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Charge Type</th>
                        <th>Fee</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in allcharges" :key="index">
                        <td>{{ item.charge_type }}</td>
                        <td>{{ item.fee }} SGD</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><strong>Total Charges</strong></td>
                        <td colspan="2">
                          <strong>{{ totalcharges }} SGD </strong>
                        </td>
                      </tr>

                      <tr>
                        <td><strong>Payment Status</strong></td>
                        <td colspan="2">
                          <span v-if="currentUser.role != 6">
                            <b-button :disabled="shipment.payment_status == 1" class="mr-2" variant="warning"
                              v-if="shipment.payment_status == 1">
                              Paid</b-button>
                            <b-button :disabled="shipment.payment_status == 1" class="mr-2" variant="warning"
                              @click="MarkasPaid" v-if="shipment.payment_status == 0">
                              Mark as paid</b-button>
                            <a href="javascript:void(0)" @click="MarkasUnPaid" v-if="shipment.payment_status == 1">Mark as
                              unpaid</a>
                          </span>
                          <span v-if="currentUser.role == 6">
                            <label v-if="shipment.payment_status == 1">Paid</label>
                            <label v-if="shipment.payment_status == 0">Unpaid</label>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Documents</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="light" @click="selectImage" v-b-modal.address-book-modal><i class="flaticon2-plus"></i>
                Add document</b-button>
              <pdf src="http://www.africau.edu/images/default/sample.pdf"></pdf>
            </div>
          </div>
          <div class="card-body body-fluid">
            <!-- <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${previewImage})` }"></div>  -->
            <input style="display: none" ref="fileInput" type="file" @input="pickFile" />
            <div class="row">
              <div class="col">
                <table class="table table-hover table-stripped table-borderless">
                  <tbody>
                    <tr v-for="(document, index) in documents" :key="index">
                      <td>
                        <button type="button" @click="downloadDocument(document.id)" class="btn btn-secondary mr-2">
                          <i class="flaticon-attachment text-primary"></i>
                          {{ document.file_name }}
                        </button>
                      </td>
                      <td>
                        <span class="badge badge-danger" @click="deleteDoc(document.id)">
                          Delete</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>

        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Admin Messages</h3>
            </div>
          </div>
          <div class="card-body body-fluid">
            <b-row>
              <b-col sm="12"> </b-col>
            </b-row>
          </div>
        </div>

        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Shipment Messages</h3>
            </div>
          </div>
          <div class="card-body body-fluid" v-if="currentUser.role != 6">
            <div class="row" v-for="item in shipmentMessages" :key="item.message">
              <div class="col-1"></div>
              <div class="col-10 message-div">
                <div class="row p-0">
                  <div class="col-10">
                    <p class="float-left">
                      {{ item.message }}
                    </p>
                  </div>
                  <div class="col-2">
                    <p class="float-right">
                      {{ item.created_at | formatDate }}
                    </p>
                  </div>
                </div>
                <b-button size="sm" variant="light" @click="deleteMessage(item.id)"
                  class="ml-2 text-danger">Delete</b-button>
              </div>
              <div class="col-1"></div>
            </div>

            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-row>
                <b-col sm="1"> </b-col>
                <b-col sm="2">
                  <label for="textarea-default"><b>Add Message</b></label>
                </b-col>
                <b-col sm="6"> </b-col>
                <b-col sm="2">
                  <label class="add-message-btn">256 characters</label>
                </b-col>
                <b-col sm="1"> </b-col>
                <b-col sm="1"> </b-col>
                <b-col sm="10">
                  <b-form-textarea id="add-shipment-message" maxlength="256" placeholder="Type your message"
                    v-model="form.shipmentmessage"></b-form-textarea>
                </b-col>
                <b-col sm="1"> </b-col>
              </b-row>
              <b-row>
                <b-col sm="1"> </b-col>
                <b-col sm="3">
                  <b-form-checkbox checked="true" v-model="form.sendmessageNot">Send email notification</b-form-checkbox>
                </b-col>
                <b-col sm="5"> </b-col>
                <b-col sm="2">
                  <b-button class="add-message-btn" @click="shipmentMessage" variant="light">Submit</b-button>
                </b-col>
              </b-row>
            </form>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="edit-shipment-modal" ref="modal" ok-title="Update" title="Edit Shipment" @ok="editeShipment"
      @hidden="resetAddStatusModal" size="xl">
      <form ref="editform" @submit.stop.prevent="handleSubmit">
        <div class="row" v-if="form.type == 1">
          <div class="col-md-12">
            <h5>Purchase Information</h5>
          </div>
          <div class="col-md-3">
            <label class="font-weight-bold">Vendor Name <span class="text-danger">*</span></label>
            <b-form-input placeholder="Amazon.com, ebay etc..." v-model="form.vendor_name"></b-form-input>
          </div>
          <div class="col-md-3">
            <label class="font-weight-bold">Courier Name <span class="text-danger">*</span></label>
            <b-form-select :options="couriers" value-field="name" v-model="form.courier" text-field="name" size="md"
              class="">
              <template #first>
                <b-form-select-option :value="null" disabled>Select Courier</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="col-md-3">
            <label class="font-weight-bold">Courier Tracking Number <span class="text-danger">*</span></label>
            <b-form-input placeholder="Tracking number" v-model="form.courier_tracking_no"></b-form-input>
          </div>
          <div class="col-md-3">
            <label class="font-weight-bold">Purchase Date <span class="text-danger">*</span></label>
            <b-form-input type="date" v-model="form.purchase_date"></b-form-input>
          </div>
          <div class="col-md-12 mt-2">
            <label class="font-weight-bold">Shipping Address <span class="text-danger">*</span></label>
            <b-form-select value-field="id" v-model="form.shipping_address" text-field="name">
              <b-form-select-option v-for="item in virtualaddresses" :key="item.id" :value="item.id">{{ item.name }}, {{
                item.address_line_1 }},
                {{ item.city_state }}, {{ item.country }}-{{
                  item.zip
                }}</b-form-select-option>
            </b-form-select>
          </div>
        </div>
        <div class="row" v-if="form.type == 2">
          <div class="col-md-12">
            <h5>Sender Details</h5>
          </div>
          <div class="col-md-3">
            <b-form-group label="Name">
              <b-form-input v-model="form.sender_name"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Phone#">
              <b-form-input v-model="form.sender_phone"></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group label="Adress">
              <b-form-input v-model="form.sender_street_address"></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group label="City">
              <b-form-input v-model="form.sender_city"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="State">
              <b-form-input v-model="form.sender_state"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Country">
              <b-form-input v-model="form.sender_country"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Zip Code">
              <b-form-input v-model="form.sender_zipcode"></b-form-input>
            </b-form-group>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <h5>Reciever Details</h5>
          </div>
          <div class="col-md-3">
            <b-form-group label="Name">
              <b-form-input v-model="form.receiver_name"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Phone#">
              <b-form-input v-model="form.receiver_phone"></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group label="Adress">
              <b-form-input v-model="form.receiver_street_address"></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group label="City">
              <b-form-input v-model="form.receiver_city"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="State">
              <b-form-input v-model="form.receiver_state"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Country">
              <b-form-input v-model="form.receiver_country"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Zip Code">
              <b-form-input v-model="form.receiver_zipcode"></b-form-input>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal id="edit-vendor-modal" ref="modal" ok-title="Update" title="Edit Vendor" @ok="editVendor"
      @hidden="resetAddStatusModal" size="lg">
      <form ref="editform" @submit.stop.prevent="handleSubmit">
        <div class="row mt-3 mb-3">
          <div class="col-md-3">
            <label class="font-weight-bold">Vendor Name <span class="text-danger">*</span></label>
            <b-form-input placeholder="Amazon.com, ebay etc..." v-model="vendor.vendor_name"></b-form-input>
          </div>
          <div class="col-md-3">
            <label class="font-weight-bold">Courier Name <span class="text-danger">*</span></label>
            <b-form-select :options="couriers" value-field="name" v-model="vendor.courier" text-field="name" size="md"
              class="">
              <template #first>
                <b-form-select-option :value="null" disabled>Select Courier</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="col-md-3">
            <label class="font-weight-bold">Tracking No. <span class="text-danger">*</span></label>
            <b-form-input placeholder="Tracking number" v-model="vendor.courier_tracking_no"></b-form-input>
          </div>
          <div class="col-md-3">
            <label class="font-weight-bold">Purchase Date <span class="text-danger">*</span></label>
            <b-form-input type="date" v-model="vendor.purchase_date"></b-form-input>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal id="add-shipment-status-modal" ref="modal" ok-title="Update" title="Update Shipment Status"
      @ok="addShipmentStatus" @hidden="resetAddStatusModal" size="lg">
      <div class="row">
        <div class="col-md-4">
          <b-form-group label="Date & Time">
            <VueCtkDateTimePicker format="YYYY-MM-DD HH:mm:ss" v-model="statusObj.datetime" />
            <!-- <b-form-input v-model="statusObj.datetime"></b-form-input> -->
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Status">
            <b-form-input v-model="statusObj.status" list="statusDataList"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Location">
            <b-form-input list="locationsDataList" v-model="statusObj.location"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Remarks">
            <b-form-input list="remarksDataList" v-model="statusObj.remarks"></b-form-input>
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <b-modal id="edit-shipment-status-modal" ref="modal" ok-title="Update" title="Update Shipment Status"
      @ok="UpdateShipmentStatus" @hidden="resetAddStatusModal" size="lg">
      <div class="row">
        <div class="col-md-4">
          <b-form-group label="Date & Time">
            <VueCtkDateTimePicker format="YYYY-MM-DD HH:mm:ss" v-model="statusObj.datetime" />
            <!-- <b-form-input v-model="statusObj.datetime"></b-form-input> -->
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Status">
            <b-form-input v-model="statusObj.status" list="statusDataList"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Location">
            <b-form-input list="locationsDataList" v-model="statusObj.location"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Remarks">
            <b-form-input list="remarksDataList" v-model="statusObj.remarks"></b-form-input>
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <b-modal id="add-product-modal" ref="modal" ok-title="Save Product" title="Add Product" @ok="addProduct"
      @hidden="resetProductModal" size="xl">
      <div class="row">
        <div class="table-responsive custom-scrollbar-transparent">
          <table class="table text-nowrap" id="productTable">
            <thead>
              <tr>
                <th>Description</th>
                <th>Weight Scale</th>
                <th>Size Scale</th>
                <th>Length</th>
                <th>Width</th>
                <th>Height</th>
                <th>Dimensional weight</th>
                <th>Actual weight</th>
                <th>Final weight</th>
                <th>Qty</th>
                <th>Currency</th>
                <th>Value</th>
                <th>Total value</th>
                <!-- <th>Remove</th> -->
              </tr>
            </thead>
            <tbody id="p_scents">
              <tr class="item">
                <td>
                  <input style="min-width: 200px" type="text" class="form-control productName" list="producttypes"
                    placeholder="Product description" id="pname" v-model="product.description" />
                </td>
                <td>
                  <b-form-select :options="weightscales" value-field="name" v-model="product.weight_scale"
                    text-field="name" class="form-control" disabled="true" @change="CalculateDimensionalWeight()">
                  </b-form-select>
                </td>
                <td>
                  <b-form-select :options="sizescales" value-field="name" v-model="product.size_scale" text-field="name"
                    class="form-control">
                  </b-form-select>
                </td>
                <td>
                  <input type="number" step=".01" placeholder="0.00" class="form-control length" name="plength[]"
                    v-model="product.length" id="plength" @keyup="CalculateDimensionalWeight()"
                    @change="CalculateDimensionalWeight()" />
                </td>
                <td>
                  <input type="number" step=".01" placeholder="0.00" class="form-control width" name="pwidth[]"
                    v-model="product.width" id="pwidth" @keyup="CalculateDimensionalWeight()"
                    @change="CalculateDimensionalWeight()" />
                </td>
                <td>
                  <input type="number" step=".01" placeholder="0.00" class="form-control height" v-model="product.height"
                    @keyup="CalculateDimensionalWeight()" @change="CalculateDimensionalWeight()" />
                </td>
                <td>
                  <input type="text" class="form-control vweight" name="pvweight[]" readonly=""
                    v-model="product.dimensional_weight" placeholder="0.00" />
                </td>
                <td>
                  <input type="text" class="form-control aweight" name="paweight[]" placeholder="0.00"
                    v-model="product.actual_weight" @change="CalculateFinalWeight()" @keyup="CalculateFinalWeight()" />
                </td>
                <td>
                  <input type="number" step=".01" class="form-control weight" readonly="" placeholder="0.00"
                    name="pweight[]" v-model="product.final_weight" />
                </td>
                <td>
                  <input type="number" placeholder="0" id="pquantity" name="pquantity[]" class="form-control quantity"
                    v-model="product.qty" @change="CalculateTotalValue()" @keyup="CalculateTotalValue()" />
                </td>
                <td>
                  <b-form-select :options="currencies" value-field="code" v-model="product.currency" text-field="code"
                    class="form-control">
                  </b-form-select>
                </td>
                <td>
                  <input type="number" step=".01" placeholder="0.00" class="form-control price" value="0.00"
                    name="pprice[]" v-model="product.value" id="pprice" @change="CalculateTotalValue()"
                    @keyup="CalculateTotalValue()" />
                </td>
                <td>
                  <input type="number" step=".01" placeholder="0.00" class="form-control total" value="0.00" readonly=""
                    v-model="product.total" name="ptotal[]" id="ptotal" />
                </td>
                <!-- <td>
                      <b-button
                        @click="removeProduct(index)"
                        class="ml-2"
                        size="sm"
                        variant="danger"
                      >
                        <i class="flaticon2-trash icon-sm"></i
                      ></b-button>
                    </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
    <b-modal id="edit-product-modal" ref="modal" ok-title="Save Changes" title="Edit Products" ok-only @ok="updateProduct"
      @hidden="resetProductModal" size="xl">
      <div class="row">
        <div class="table-responsive custom-scrollbar-transparent">
          <table class="table text-nowrap" id="productTable">
            <thead>
              <tr>
                <th>Description</th>
                <!-- <th>Weight Scale</th> -->
                <th>Size Scale</th>
                <th>Length</th>
                <th>Width</th>
                <th>Height</th>
                <th>Dimensional weight</th>
                <th>Actual weight</th>
                <th>Final weight</th>
                <th>Qty</th>
                <th>Currency</th>
                <th>Value</th>
                <th>Total value</th>
                <!-- <th>Remove</th> -->
              </tr>
            </thead>
            <tbody id="p_scents">
              <tr class="item">
                <td>
                  <input style="min-width: 200px" type="text" class="form-control productName" list="producttypes"
                    placeholder="Product description" id="pname" v-model="product.description" />
                </td>
                <!-- <td>
                  <b-form-select
                    :options="weightscales"
                    value-field="name"
                    v-model="product.weight_scale"
                    text-field="name"
                    class="form-control"
                    disabled="true"
                    @change="CalculateDimensionalWeight()"
                  >
                  </b-form-select>
                </td> -->
                <td>
                  <b-form-select :options="sizescales" value-field="name" v-model="product.size_scale" text-field="name"
                    class="form-control">
                  </b-form-select>
                </td>
                <td>
                  <input type="number" step=".01" placeholder="0.00" class="form-control length" name="plength[]"
                    v-model="product.length" id="plength" @keyup="CalculateDimensionalWeight()"
                    @change="CalculateDimensionalWeight()" />
                </td>
                <td>
                  <input type="number" step=".01" placeholder="0.00" class="form-control width" name="pwidth[]"
                    v-model="product.width" id="pwidth" @keyup="CalculateDimensionalWeight()"
                    @change="CalculateDimensionalWeight()" />
                </td>
                <td>
                  <input type="number" step=".01" placeholder="0.00" class="form-control height" v-model="product.height"
                    @keyup="CalculateDimensionalWeight()" @change="CalculateDimensionalWeight()" />
                </td>
                <td>
                  <input type="text" class="form-control vweight" name="pvweight[]" readonly=""
                    v-model="product.dimensional_weight" placeholder="0.00" />
                </td>
                <td>
                  <input type="text" class="form-control aweight" name="paweight[]" placeholder="0.00"
                    v-model="product.actual_weight" @change="CalculateFinalWeight()" @keyup="CalculateFinalWeight()" />
                </td>
                <td>
                  <input type="number" step=".01" class="form-control weight" readonly="" placeholder="0.00"
                    name="pweight[]" v-model="product.final_weight" />
                </td>
                <td>
                  <input type="number" placeholder="0" id="pquantity" name="pquantity[]" class="form-control quantity"
                    v-model="product.qty" @change="CalculateTotalValue()" @keyup="CalculateTotalValue()" />
                </td>
                <td>
                  <b-form-select :options="currencies" value-field="code" v-model="product.currency" text-field="code"
                    class="form-control">
                  </b-form-select>
                </td>
                <td>
                  <input type="number" step=".01" placeholder="0.00" class="form-control price" value="0.00"
                    name="pprice[]" v-model="product.value" id="pprice" @change="CalculateTotalValue()"
                    @keyup="CalculateTotalValue()" />
                </td>
                <td>
                  <input type="number" step=".01" placeholder="0.00" class="form-control total" value="0.00" readonly=""
                    v-model="product.total" name="ptotal[]" id="ptotal" />
                </td>
                <!-- <td>
                      <b-button
                        @click="removeProduct(index)"
                        class="ml-2"
                        size="sm"
                        variant="danger"
                      >
                        <i class="flaticon2-trash icon-sm"></i
                      ></b-button>
                    </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
    <!-- Modal Shipment Charges Start  -->
    <b-modal id="add-charges-modal" ref="modal" ok-title="Submit" title="Add / Edit Charges"
      @hidden="resetAddchargesModal" size="lg">
      <div class="row">
        <div class="col-md-5">
          <b-form-group label="Charge Name">
            <b-form-select :options="ratenamelist" value-field="name" v-model="chargesObj.charge_type" text-field="name">
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-5">
          <b-form-group label="Amount (SGD)">
            <b-form-input v-model="chargesObj.fee" placeholder="0.00"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-button @click="addShippingCharges" class="custom-margin-btn" variant="light"><i class="flaticon2-plus"></i>
            Add</b-button>
        </div>
      </div>
      <div class="row" v-for="(item, index) in allcharges" :key="index">
        <div class="col-md-5">
          {{ item.charge_type }}
        </div>
        <div class="col-md-5">
          {{ item.fee }}
        </div>
        <div class="col-md-2">
          <b-button size="sm" variant="light" @click="DeleteCharge(item.id)" class="ml-2 text-danger">Delete</b-button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5">
          <label class="font-weight-bold">Total</label>
        </div>
        <div class="col-md-5">{{ this.totalcharges }}</div>
        <div class="col-md-2"></div>
      </div>
    </b-modal>
    <!-- Modal Shipment Charges End -->
    <!-- Modal Calculate Shipment Charges Start  -->
    <b-modal id="auto-charge-modal" ref="modal" ok-title="Calculate Charges" @ok="autoCharge" title="Calculate Charges"
      @hidden="resetAutoChargesModal" size="md">
      <div class="row">
        <div class="col-md-4">
          <b-form-group label="Shipment type">
            <b-form-select :options="shipmenttypelist" value-field="name" v-model="autochargesObj.type" text-field="name">
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Shipping Mode">
            <b-form-select :options="shippingmodelist" value-field="name" v-model="autochargesObj.mode" text-field="name">
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Weight (Kgs)">
            <b-form-input v-model="autochargesObj.weight" placeholder="0.00"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6 offset-md-6" v-if="allcharges.length != 0">
          <b-form-checkbox class="float-right" v-model="autochargesObj.replaceExistingCharges">Replace existing
            charges</b-form-checkbox>
        </div>
      </div>
    </b-modal>
    <!-- Modal Calculate Charges End -->
    <datalist id="statusDataList">
      <option v-for="(item, index) in statusesDataList" :key="index">
        {{ item.name }}
      </option>
    </datalist>
    <datalist id="locationsDataList">
      <option v-for="(item, index) in locationDataList" :key="index">
        {{ item.name
        }}<span v-if="item.countrycode != null">, {{ item.countrycode }}</span>
      </option>
    </datalist>
    <datalist id="remarksDataList">
      <option v-for="(item, index) in remarksDataList" :key="index">
        {{ item.name }}
      </option>
    </datalist>
    <datalist id="producttypes">
      <option v-for="(item, index) in proddesclist" :key="index">
        {{ item.name }}
      </option>
    </datalist>
    <b-tooltip :show.sync="show" triggers="click" target="btn-copy" placement="top">
      <strong>Copied!</strong>
    </b-tooltip>
    <loading :active.sync="isLoading"></loading>
  </div>
</template>

<style>
.custom-margin-btn {
  margin-top: 2rem !important;
}

.header_style_2 {
  font-size: 13px;
  margin: 5px 0;
  font-weight: 500;
  color: #6777c4;
  text-transform: uppercase;
}

#productTable .table>tbody>tr>td,
#productTable .table>thead>tr>th {
  padding: 10px 10px;
  line-height: 30px;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>th {
  padding: 10px;
}

.scale,
.length,
.width,
.height {
  width: 70px;
}

.quantity {
  width: 70px;
}

.price,
.tax,
.total {
  width: 70px;
}

.currency,
.aweight,
.weight {
  width: 70px;
}

.productName {
  width: 120px;
}

#productTable .form-control {
  min-width: 100px;
}

.vweight {
  min-width: 140px !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      sendMessageOnEmail: "accepted",
      form: {},
      previewImage: null,
      file: null,
      id: this.$route.params.id,
      PublicUrl: window.location.origin,
      publicPath: process.env.BASE_URL,
      isLoading: false,
      trackingLink: "",
      show: false,
      fullPage: true,
      currentStatus: "",
      shipment: {},
      statusObj: {
        id: null,
        shipment_id: this.$route.params.id,
        datetime: "",
        status: "",
        location: "",
        remarks: "",
      },
      product: {
        id: null,
        pre_alert_id: this.$route.params.id,
        description: null,
        weight_scale: "Kgs",
        size_scale: "CM",
        length: null,
        width: null,
        height: null,
        dimensional_weight: null,
        actual_weight: null,
        final_weight: null,
        qty: null,
        currency: "USD",
        value: null,
        total: null,
      },
      autochargesObj: {
        pre_alert_id: this.$route.params.id,
        type: "General",
        mode: "Truck",
        weight: null,
        replaceExistingCharges: null,
      },
      chargesObj: {
        id: null,
        pre_alert_id: this.$route.params.id,
        charge_type: "Freight",
        fee: null,
      },
      statusesDataList: [],
      locationDataList: [],
      remarksDataList: [],
      proddesclist: [],
      allcharges: [],
      zones: [],
      firstweightscale: null,
      firstsizescale: null,
      firstcurrency: null,
      events: [],
      couriers: [],
      virtualaddresses: [],
      documents: [],
      input: null,
      totalcharges: 0.0,
      vendor: {
        id:null,
        vendor_name: null,
        courier_tracking_no: null,
        purchase_invoice_url: null,
        purchase_date: null,
        courier: null,
        file: null,
      },
      ratenamelist: [
        {
          name: "Freight",
        },
        {
          name: "Handling",
        },
        {
          name: "GST",
        },
        {
          name: "Warehousing",
        },
        {
          name: "Consolidating",
        },
        {
          name: "Repacking",
        },
        {
          name: "Pick Up",
        },
        {
          name: "Discount",
        },
        {
          name: "Permit",
        },
        {
          name: "Paid On Behalf",
        },
        {
          name: "Manpower",
        },
        {
          name: "Delivery",
        },
      ],
      shipmentMessages: [],
      nonce: 0,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  components: {
    Loading,
  },
  computed: {
    timeline() {
      return this.events.slice().reverse();
    },
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Shipments",
      },
      {
        title: "View Shipment",
      },
    ]);
    this.isLoading = true;
    this.loadData();
    this.getShipment();
    this.getStatusDataList();
    this.getLocationsDataList();
    this.getRemarksDataList();
    this.getCharges();
    this.getAllShipmentMessages(this.id);
    this.getAllDocuments(this.id);
  },
  methods: {
    ShowEditModal(id) {
      this.isLoading = true;
      ApiService.get("shipmentdata", id)
        .then(({ data }) => {
          this.form = data;
          this.isLoading = false;
          this.$bvModal.show("edit-shipment-modal");
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    showCopyTooltip() {
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 1500);
    },
    CalculateDimensionalWeight() {
      if (
        this.product.width > 0 &&
        this.product.height > 0 &&
        this.product.length > 0
      ) {
        // Calculate Dimensional Weight when weight scale is KG

        if (this.product.weight_scale.toLowerCase() == "Kgs".toLowerCase()) {
          this.product.dimensional_weight =
            (this.product.width * this.product.height * this.product.length) /
            5000;
        }
        // Calculate Dimensional Weight when weight scale is M3
        if (this.product.weight_scale.toLowerCase() == "M3".toLowerCase()) {
          this.product.dimensional_weight =
            (this.product.width / 100) *
            (this.product.height / 100) *
            (this.product.length / 100);
        }
        this.CalculateFinalWeight();
      } else {
        this.product.dimensional_weight = 0;
      }
      //  this.CalculateTotalWeight();
    },
    CalculateFinalWeight() {
      if (
        parseFloat(this.product.dimensional_weight) >=
        parseFloat(this.product.actual_weight)
      ) {
        this.product.final_weight = this.product.dimensional_weight;
      } else {
        this.product.final_weight = this.product.actual_weight;
      }
      //  this.CalculateTotalWeight();
    },
    CalculateTotalValue() {
      if (this.product.qty > 0 && this.product.value > 0) {
        this.product.total = this.product.qty * this.product.value;
      } else {
        this.product.total = 0;
      }
    },
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      this.file = input.files[0];
      if (this.file) {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("shipment_id", this.id);
        ApiService.upload("adddocument", formData)
          .then((response) => {
            if (response.data.success) {
              this.isLoading = false;
              this.getAllDocuments(this.id);
            }
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },

    editeShipment(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      this.isLoading = true;
      ApiService.update("editshipment", this.form.id, this.form)
        .then(() => {
          this.getShipment();
          this.$nextTick(() => {
            this.$bvModal.hide("edit-shipment-modal");
            this.makeToastVariant(
              "success",
              "Shipment updated successfully",
              "Success"
            );
          });
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
    },
    shipmentMessage() {
      // Prevent modal from closing
      this.isLoading = true;
      ApiService.update("shipmentmessage", this.id, this.form)
        .then((res) => {
          this.form.shipmentmessage = "";

          // this.getShipment();
          if (res.data.success) {
            this.shipmentMessages = res.data.messageList;
          }
          this.$nextTick(() => {
            this.makeToastVariant(
              "success",
              "Message added successfully",
              "Success"
            );
          });
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
    },
    getAllWeightScales() {
      ApiService.get("weightscale")
        .then(({ data }) => {
          this.weightscales = data;
          this.firstweightscale = this.weightscales.length
            ? this.weightscales[0].name
            : null;
        })
        .catch(() => { });
    },
    getAllShipmentTypes() {
      ApiService.get("shipmenttype")
        .then(({ data }) => {
          this.shipmenttypelist = data;
        })
        .catch(() => { });
    },
    getAllShippingModes() {
      ApiService.get("shippingmode")
        .then(({ data }) => {
          this.shippingmodelist = data;
        })
        .catch(() => { });
    },
    getAllSizeScales() {
      ApiService.get("sizescale")
        .then(({ data }) => {
          this.sizescales = data;
          this.firstsizescale = this.sizescales.length
            ? this.sizescales[0].name
            : null;
        })
        .catch(() => { });
    },
    getAllCurrencies() {
      ApiService.get("currency")
        .then(({ data }) => {
          this.currencies = data;
          this.firstcurrency = this.currencies.length
            ? this.currencies[0].code
            : null;
        })
        .catch(() => { });
    },
    getAllprodDescription() {
      ApiService.get("productdescription")
        .then(({ data }) => {
          this.proddesclist = data;
        })
        .catch(() => { });
    },

    resetProductModal() {
      this.product.description = null;
      this.product.weight_scale = "Kgs";
      this.product.size_scale = "CM";
      this.product.length = null;
      this.product.width = null;
      this.product.height = null;
      this.product.dimensional_weight = null;
      this.product.actual_weight = null;
      this.product.final_weight = null;
      this.product.qty = null;
      this.product.currency = "USD";
      this.product.value = null;
      this.product.total = null;
    },
    resetAddchargesModal() {
      this.chargesObj.charge_type = "Freight";
      this.chargesObj.fee = null;
    },
    checkErrorsCharges() {
      if (this.chargesObj.fee) {
        return true;
      }

      this.errors = [];

      if (!this.chargesObj.fee) {
        this.errors.push("Amount is required.");
      }
      // if (!this.form.to) {
      //   this.errors.push("To is required.");
      // }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    addShippingCharges() {
      // bvModalEvt.preventDefault();
      if (this.checkErrorsCharges() == true) {
        this.isLoading = true;
        ApiService.post("shippingcharges", this.chargesObj)
          .then(() => {
            this.getCharges();
            this.resetAddchargesModal();
            this.$nextTick(() => {
              // this.$bvModal.hide("add-charges-modal");
              this.makeToastVariant(
                "success",
                "Charges added successfully",
                "Success"
              );
            });
            this.isLoading = false;
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    resetAutoChargesModal() {
      this.autochargesObj.weight = this.shipment.final_weight;
      this.autochargesObj.mode = this.shipment.shipping_mode;
      this.autochargesObj.type = this.shipment.shipment_type;
      this.autochargesObj.replaceExistingCharges = null;
    },
    checkErrorsAutoCharge() {
      if (this.autochargesObj.weight) {
        return true;
      }
      this.errors = [];
      if (!this.chargesObj.weight) {
        this.errors.push("Weight is required.");
      }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    autoCharge(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.allcharges.length != 0) {
        if (this.autochargesObj.replaceExistingCharges != true) {
          this.makeToastVariant("danger", "Charges are already added", "Error");
          return;
        }
      }
      if (this.checkErrorsAutoCharge() == true) {
        this.isLoading = true;
        ApiService.post("autochargeshipment", this.autochargesObj)
          .then((res) => {
            if (res.data.success) {
              this.getCharges();
              this.$nextTick(() => {
                this.$bvModal.hide("auto-charge-modal");
                this.makeToastVariant(
                  "success",
                  "Charges added successfully",
                  "Success"
                );
                this.isLoading = false;
              });
            } else {
              this.isLoading = false;
              this.getCharges();
              this.makeToastVariant("danger", res.data.msg, "Error");
            }
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    addProduct(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      //   if (this.checkErrors() == true) {
      this.isLoading = true;
      ApiService.post("addshipmentproduct", this.product)
        .then(() => {
          this.getShipment();
          this.$nextTick(() => {
            this.$bvModal.hide("add-product-modal");
            this.makeToastVariant(
              "success",
              "Product added successfully",
              "Success"
            );
          });
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
      //   }
    },
    updateProduct(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isLoading = true;
      ApiService.update("updateshipmentproduct", this.product.id, this.product)
        .then(() => {
          this.getShipment();
          this.$nextTick(() => {
            this.$bvModal.hide("edit-product-modal");
            this.makeToastVariant(
              "info",
              "Product updated successfully",
              "Success"
            );
          });
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
    },
    MarkasPaid() {
      this.isLoading = true;
      ApiService.update("markAsPaid", this.id, null)
        .then(() => {
          this.getShipment();
          this.$nextTick(() => {
            this.makeToastVariant("info", "Shipment marked as paid", "Success");
          });
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
    },
    MarkasUnPaid() {
      this.isLoading = true;
      ApiService.update("markAsUnPaid", this.id, null)
        .then(() => {
          this.getShipment();
          this.$nextTick(() => {
            this.makeToastVariant(
              "info",
              "Shipment marked as Un paid",
              "Success"
            );
          });
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
    },
    ShowProductEditModal(id) {
      ApiService.get("getshipmentproduct", id)
        .then(({ data }) => {
          this.product = data.product;
          this.$bvModal.show("edit-product-modal");
        })
        .catch(() => { });
    },
    addShipmentStatus(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      //   if (this.checkErrors() == true) {
      this.isLoading = true;
      ApiService.post("shipmentstatus", this.statusObj)
        .then(() => {
          this.getShipment();
          this.$nextTick(() => {
            this.$bvModal.hide("add-shipment-status-modal");
            this.makeToastVariant(
              "success",
              "Shipment status updated successfully",
              "Success"
            );
          });
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
      //   }
    },
    UpdateShipmentStatus(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isLoading = true;
      ApiService.update("shipmentstatus", this.statusObj.id, this.statusObj)
        .then(() => {
          this.getShipment();
          this.$nextTick(() => {
            this.$bvModal.hide("edit-shipment-status-modal");
            this.makeToastVariant(
              "info",
              "Shipment status updated successfully",
              "Success"
            );
          });
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
    },
    resetAddStatusModal() {
      this.statusObj.datetime = "";
      this.statusObj.status = "";
      this.statusObj.location = "";
      this.statusObj.remarks = "";
    },
    showAutoChargeModal() {
      // Give Data to AutoChargeObj for further calculation
      this.autochargesObj.weight = this.shipment.total_weight;
      this.autochargesObj.type = this.shipment.shipment_type;
      this.autochargesObj.mode = this.shipment.shipping_mode;
      this.$bvModal.show("auto-charge-modal");
    },
    getShipment() {
      ApiService.get("prealert", this.id)
        .then(({ data }) => {
          this.shipment = data.shipment;
          // console.log(data.shipment);
          this.currentStatus = this.shipment.shipmentstatuses.length
            ? this.shipment.shipmentstatuses[0].status
            : null;
          this.trackingLink =
            this.PublicUrl +
            this.publicPath +
            "track/shipment/" +
            this.shipment.tracking_no;

          //   console.log(data.shipment);
        })
        .catch(() => { });
    },
    EditShipmentStatus(id) {
      this.isLoading = true;
      ApiService.get("shipmentstatus", id)
        .then(({ data }) => {
          this.statusObj = data;
          this.$bvModal.show("edit-shipment-status-modal");
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    getStatusDataList() {
      ApiService.get("shipmentstatuslist")
        .then(({ data }) => {
          this.statusesDataList = data;
        })
        .catch(() => { });
    },
    getCharges() {
      ApiService.get("getShippingchargesbyShipment", this.id)
        .then(({ data }) => {
          this.allcharges = data;
          this.totalcharges = 0.0;
          this.allcharges.forEach((value) => {
            this.totalcharges =
              parseFloat(this.totalcharges) + parseFloat(value.fee);
          });
          this.isLoading = false;
        })
        .catch(() => { });
    },

    getLocationsDataList() {
      ApiService.get("getlocationsforshipmentstatus")
        .then(({ data }) => {
          this.locationDataList = data.locations;
        })
        .catch(() => { });
    },
    getRemarksDataList() {
      ApiService.get("remarksstatuslist")
        .then(({ data }) => {
          this.remarksDataList = data;
        })
        .catch(() => { });
    },
    getAllZones() {
      ApiService.get("shippingzone")
        .then(({ data }) => {
          this.zones = data;
        })
        .catch(() => { });
    },
    deleteStatus(id) {
      this.isLoading = true;
      ApiService.delete("shipmentstatus", id)
        .then(() => {
          this.getShipment();
          this.makeToastVariant(
            "warning",
            "Shipment status deleted successfully",
            "Success"
          );
          this.isLoading = false;
        })
        .catch(() => { });
    },
    DeleteProduct(id) {
      this.isLoading = true;
      ApiService.delete("deletesingleproductshipment", id)
        .then(() => {
          this.getShipment();
          this.makeToastVariant(
            "warning",
            "Product deleted successfully",
            "Success"
          );
          this.isLoading = false;
        })
        .catch(() => { });
    },
    DeleteCharge(id) {
      this.isLoading = true;
      ApiService.delete("shippingcharges", id)
        .then(() => {
          this.getCharges();
          this.makeToastVariant(
            "warning",
            "Charge deleted successfully",
            "Success"
          );
          this.isLoading = false;
        })
        .catch(() => { });
    },
    getAllShipmentMessages(id) {
      ApiService.get("shipmentmessages", id)
        .then(({ data }) => {
          this.shipmentMessages = data.messageList;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    deleteMessage(id) {
      this.isLoading = true;
      ApiService.delete("deleteShipmentMessage", id)
        .then(() => {
          this.makeToastVariant(
            "warning",
            "Message deleted successfully",
            "Success"
          );
          this.isLoading = false;
          this.getAllShipmentMessages(this.id);
        })
        .catch(() => { });
    },
    getAllVA() {
      ApiService.get("virtualaddress")
        .then(({ data }) => {
          this.virtualaddresses = data;
        })
        .catch(() => { });
    },
    getAllCouriers() {
      ApiService.get("courier")
        .then(({ data }) => {
          this.couriers = data;
        })
        .catch(() => { });
    },
    getAllDocuments(id) {
      ApiService.get("getShipmentDocuments", id)
        .then(({ data }) => {
          debugger;
          this.documents = data.list;
        })
        .catch(() => { });
    },
    loadData() {
      ApiService.get("getpageload")
        .then(({ data }) => {
          this.addressbook = data.addressbook;
          this.countries = data.countries;
          this.form.receiver_country = this.countries.length
            ? this.countries[0].name
            : null;

          this.virtualaddresses = data.va;
          this.form.shipping_address = this.virtualaddresses.length
            ? this.virtualaddresses[0].id
            : null;

          this.proddesclist = data.productDescriptions;
          this.couriers = data.courier;
          this.shippingmodelist = data.shippingModes;
          this.form.shipping_mode = this.shippingmodelist.length
            ? this.shippingmodelist[0].name
            : null;
          this.shipmenttypelist = data.shipmentTypes;
          this.form.shipment_type = this.shipmenttypelist.length
            ? this.shipmenttypelist[0].name
            : null;
          this.currencies = data.currencies;
          this.firstcurrency = this.currencies.length
            ? this.currencies[0].code
            : null;
          this.sizescales = data.sizescales;
          this.firstsizescale = this.sizescales.length
            ? this.sizescales[0].name
            : null;
          this.weightscales = data.weightScales;
          this.firstweightscale = this.weightscales.length
            ? this.weightscales[0].name
            : null;

          this.isLoading = false;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    printInvoice() {
      window.open(this.baseUrl + "invoice_pdf/" + this.id, "_blank");
    },
    printPackageReceipt() {
      window.open(this.baseUrl + "package_receipt/" + this.id, "_blank");
    },
    printMasterLabel() {
      window.open(this.baseUrl + "masterlabel/" + this.id, "_blank");
    },
    downloadDocument(id) {
      ApiService.get("downloadDocument", id)
        .then(({ data }) => {
          if (data.success) {
            window.open(this.baseUrl + data.file_path, "_blank");
          }
        })
        .catch(() => { });
      // ApiService.downloadFile(this.baseUrl + "downloadDocument",id);
    },
    downloadVendorDocument(id) {
      ApiService.get("downloadVendorDocument", id)
        .then(({ data }) => {
          if (data.success) {
            window.open(this.baseUrl + data.file_path, "_blank");
          }
        })
        .catch(() => { });
    },
    printShipmentLabel() {
      window.open(this.baseUrl + "shipmentlabel/" + this.id, "_blank");
    },
    deleteDoc(id) {
      ApiService.delete("deleteShipmentDocument", id)
        .then((response) => {
          if (response.data.success) {

            this.getAllDocuments(this.id);
            this.makeToastVariant(
              "success",
              "Document Deleted successfully",
              "Success"
            );
          } else {
            this.makeToastVariant(
              "danger",
              response.data.message,
              "Error"
            );
          }
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
    },
    editVendorPopup(v) {
      this.vendor = {
        id: v.id,
        vendor_name: v.vendor_name,
        courier_tracking_no: v.courier_tracking_no,
        purchase_invoice_url: v.purchase_invoice_url,
        purchase_date: v.purchase_date,
        courier: v.courier
      },
        this.$bvModal.show("edit-vendor-modal");
    },
    editVendor(){
      this.isLoading = true;
      ApiService.post("updatePrealertVendor", this.vendor)
        .then(() => {
          this.getShipment();
          this.$nextTick(() => {
            this.$bvModal.hide("edit-vendor-modal");
            this.makeToastVariant(
              "success",
              "Vendor Updated Successsfully",
              "Success"
            );
          });
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
    }
  },
};
</script>

<style scoped lang="scss">
.imagePreviewWrapper {
  width: 100%;
  height: 300px;
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center;
}

.col-md-3 {
  padding-top: 0;
  padding-bottom: 0;
}

.edit-shipment-btn {
  margin-top: auto;
  margin-bottom: auto;
  height: 50px;
}

.add-message-btn {
  float: right;
}

.message-div {
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 15px;
}

.link-color {
  color: blue;
}
</style>
